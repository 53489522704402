import React from "react"
import { HubspotProvider } from "@aaronhayes/react-use-hubspot-form"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"

export const wrapRootElement = ({ element }) => {
  return (
    <HubspotProvider async={false} addToHead={true}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {element}
      </LocalizationProvider>
    </HubspotProvider>
  )
}
